//Mobile------------------------------------------------------------------------------------------------------
.floater {
	display: none;
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
	.floater {
		display: block;
		position:absolute;
		transform:translate(-50%,-50%);
		top: 50%;
		left: 50%;
		z-index: 10;
		color: #fff;
		line-height: 1;
		pointer-events: none;
		visibility: hidden;
		opacity: 0;
		white-space: nowrap;
		transition: opacity 0.3s 0s, visibility 0s 0.3s, width 0.4s;
		&.show {
			transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.4s;
			visibility: visible;
			opacity: 1;
		}
	}
}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
