//Mobile------------------------------------------------------------------------------------------------------
.about {
	padding: var(--padding-mobile);
	padding-bottom: var(--padding-big);
	h2 {
		font-size: 0.875rem;
	}
	p.h2 {
		font-size: clamp(2.6875rem, 6.33vw , 4.375rem);
		line-height: 0.7777777777777778;
		margin-top: 70px;
		max-width: 1098px;
	}
	.text-block {
		margin-left: 90px;
	}

	border-bottom: 1px solid var(--color-primary);

}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.about {
		pointer-events: none;
		padding: var(--padding-desktop);
		padding-bottom: 150px;
		* {
			z-index: 1;
		}
		h2 {
			margin-left: 90px;
			span {
				margin-left: 0;
				margin-right: 40px;
				width: 320px;
			}
		}
		p.h2 {
			width: 72%;
			max-width: 1015px;
		}
		.text-block {
			margin-left: 0;
			margin-right: 40px;
			width: 320px;
		}
		.blocks {
			margin-top: 160px;
			margin-left: 90px;
		}
		position: relative;
		overflow: hidden;
	}


}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.about {
		.blocks,
		h2 {
			margin-left: clamp(90px,38.5vw,550px);
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
