/* M-regular - latin */
@font-face {
	font-family: "Matter";
	font-style: normal;
	font-weight: 400;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/M-Regular.woff") format("woff"); /* Modern Browsers */
}
/* M-SemiBold - latin */
@font-face {
	font-family: "Matter";
	font-style: normal;
	font-weight: 700;
	font-display: swap; // font-display: optional (da testare)
	src: local(""), url("../fonts/M-SemiBold.woff") format("woff"); /* Modern Browsers */
}
