body {
	font-family: var(--font-primary);
	text-transform: uppercase;
	//don't set a font size, most browsers use 16px
	font-weight: 700;
}

p,
ul,
ol {
	font-size: var(--p-font-size);
	line-height: var(--p-line-height);
	color: var(--p-color);
	margin-top: 0;
	margin-bottom: var(--p-margin-bottom);

}

.ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {

}

h1 {
	line-height: 0.83333;
	font-size: clamp(2.6875rem, 7.6vw, var(--h1-font-size));
}

h2 {
	line-height: 0.74747;
}

hr {
	border: none;
	height: var(--hr-size);
	background-color: var(--hr-color);
	margin-top: var(--hr-margin);
	margin-bottom: var(--hr-margin);
}

.lowercase {
	text-transform: lowercase;
}

// .h# 1-6 classes fontsize
@for $i from 1 through 6 {
	.h#{$i} {
		font-size: var(--h#{$i}-font-size);
	}
}
