//Mobile------------------------------------------------------------------------------------------------------
.background {
	position: fixed;
	inset: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	overflow: hidden;
	.button {
		content: '';
		background-color: var(--sun-color);
		position: absolute;
		inset: 0;
		width: 48.25rem;
		height: 48.25rem;
		border-radius: 48.25rem;
		top: -24rem;
		left: 0;
		//transform: translateX(-50%);
		filter: blur(100px);
		//opacity: 0.8;
	}
	.cursor {
		visibility: hidden;
	}
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-down(md) {
	.safari,
	.ios {
		.background {
			.button {
				transform: translate3d(15%, 0, 0) !important; //fixare qui
			}
		}
	}
}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.background {
		.button {
			width: 40vw;
			height: 40vw;
			border-radius: 50vw;
			top: calc(50% - 20vw);
			left: calc(50% - 20vw);
			filter: blur(6vw);
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
