//Mobile------------------------------------------------------------------------------------------------------
html,
body {
	scroll-behavior: smooth;
	overflow-x: hidden;
	position: relative;
}

body {
	background-color: var(--body-bg);
	color: var(--body-color);
	position: relative;
}

a {
	color: var(--link-color);
}

#dark-zone {
	background-color: var(--color-primary);
}

main {
	position: relative;
	overflow: hidden;
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {
}

//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
}

//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {
}

//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {
}

//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {
	button,
	input[type="checkbox"] + label,
	input[type="radio"] + label {
		&:hover {
			cursor: pointer;
		}
	}
}

//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {
}
