//Mobile------------------------------------------------------------------------------------------------------
.contact {
	overflow: hidden;
	padding: 40vh var(--padding-mobile) 18px;
	h2 {
		font-size: clamp(2.6875rem, 6.33vw , 5.625rem);
		line-height: 0.7777777777777778;
		max-width: 880px;
		margin-bottom: var(--padding-bigger);
	}
	.text-block {
		margin-left: 90px;
		padding-bottom: var(--padding-bigger);
	}
	.list {
		list-style-type: none;
		font-size: clamp(0.85rem,3.5vw,0.9375rem);
		line-height: 1.4;
		margin-bottom: 0;
		&:first-child {
			margin-bottom: 48px;
		}
	}
	.footer {
		line-height: 1;
		font-size:  0.9375rem;
		.logo-lottie {
			display: inline-block;
			width: 33px;
			cursor: none;
		}
		.lottie-el {
			margin: 0 4px 0 5px;
			transform: translateY(-2px);
			display: inline-block;
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.contact {
		position: relative;
		padding: 50vh var(--padding-desktop) var(--padding-desktop);
		h2,
		.text-block,
		.footer {
			z-index: 1;
			position: relative;
		}
		h2 {
			width: 70%;
			margin-bottom: 300px;
		}

		.footer {
			.logo,
			.talk {
				width: 160px;
			}
			.talk {
				text-align: right;
			}
		}
	}

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.contact {
		.text-block {
			width: 30%;
			margin-left: 0;
			ul {
				padding-left: 0;
			}
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
