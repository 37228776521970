//Mobile------------------------------------------------------------------------------------------------------
.button {
	background-color: var(--color-primary);
	color: var(--body-bg);
	font-size: 0.85rem;
	padding: 18px 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 48px;
	text-transform: uppercase;
	border: none;
	line-height: 1;
	text-decoration: none;
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {

}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
