:root {
	//-colors
	--color-primary: #222;
	--color-accent: #E5E530;
	--sun-color: #eaea6d;
	--text-color: var(--color-primary);
	--link-color: var(--color-primary);
	//-general
	--body-bg: #f4f4f4;
	--body-color: var(--color-primary);
	//-fonts
	--font-primary: "Matter", sans-serif;
	//-typography
	--h1-font-size: 5.625rem;
	--h2-font-size: 6.1875rem;
	--h3-font-size: 1.5rem;
	--h4-font-size: 1.25rem;
	--h5-font-size: 1.125rem;
	--h6-font-size: 1rem;
	--p-font-size: 0.85rem;
	--p-margin-bottom: 1em;
	--p-line-height: 1.3333333;
	--hr-size: 1px;
	--hr-color: var(--color-primary);
	--hr-margin: 1rem;
	//-transition
	--transition-default: all 0.3s ease-in-out;
	//-icon
	--icon-size-sm: 16px;
	--icon-size: 24px;
	--icon-size-lg: 32px;
	--icon-size-xl: 40px;
	// focus outline
	--outline-color: var(--color-primary);
	--outline-width: 2px;
	// padding & margin
	--padding-small: 3px;
	--padding-big: 80px;
	--padding-bigger: 140px;
	--padding-mobile: var(--padding-small);
	--padding-desktop: 9px;
	// carousel
	--carousel-ratio: 59.0625%;
}

//dark theme
@media (prefers-color-scheme: dark) {
	:root {
		//-general
	}
}
