//Mobile------------------------------------------------------------------------------------------------------
.topic {
	overflow: hidden;
	//padding-bottom: var(--padding-bigger);
	padding-top: 50vh;
	&.pt-small {
		padding-top: 60px;
	}
	h2 {
		font-size: 0.875rem;
		line-height: normal;
		padding: 0 var(--padding-small);
		margin-bottom: var(--padding-big);
	}
	h3 {
		padding-left: 40px;
		font-size: 2.6875rem;
		line-height: 0.8080808080808081;
		margin-bottom: var(--padding-big);
	}
	.description {
		padding: 0 20px 0 40px;
		margin-bottom: 125px;
		p {
			font-size: 0.9375rem;
			margin-bottom: 0;
		}
	}
	lottie-player {
		flex-shrink: 0;
		width: 150px;
		height: 130px;
		margin-right: 40px;
		margin-top: -10px;
	}
	.list {
		list-style-type: none;
		font-size: 0.9375rem;
		line-height: normal;
		margin-bottom: 0;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-only(md) {
	.topic {
		.description {
			p {
				max-width: 320px;
			}
		}
		.detail {
			h3,
			ul {
				width: 40%;
			}
		}
	}
}

//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.topic {
		padding-top: 60vh;
		h2 {
			margin-right: var(--padding-big);
			//padding-left: 70%;
		}

		&.pt-small {
			padding-top: 60px;
		}

		h3 {
			padding-left: var(--padding-small);
			padding-right: var(--padding-big);
			margin-bottom: 0;
			font-size: clamp(2.6875rem,5.5vw,6.1875rem);
			line-height: 0.74747;
			//max-width: 880px;
			width: 70%;
		}

		.description {
			padding: 0;
			margin-bottom: 205px;
		}

		.detail {
			p {
				max-width: 320px;
				font-size: 1.875rem;
				line-height: 1;
				padding-left: var(--padding-small);
				margin-bottom: 0;
			}
		}

		.list {
			width: 30%;
			margin-left: 0;
			margin-right: 0;
			margin-bottom: -6px;
			padding-right: var(--padding-big);
			padding-left: 0;
		}

		lottie-player {
			display: flex;
			justify-content: flex-start;
		}



	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
