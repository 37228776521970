//Mobile------------------------------------------------------------------------------------------------------
.header {
	line-height: 1;
	padding: 14px;
	font-size:  0.9375rem;
	#contactMobileFloating {
		position: fixed;
		z-index: 10;
		top: 10px;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		visibility: hidden;
		opacity: 0;
		//margin-left: 11px;
		transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.4s, color 0.3s, background-color 0.3s;
		&.show {
			transform: translateX(-50%) translateY(0%);
			transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.4s, color 0.3s, background-color 0.3s;
			visibility: visible;
			opacity: 1;
		}
		&.negative {
			background-color: #fff;
			color: #000;
		}
	}

	.logo-lottie {
		display: inline-block;
		width: 33px;
		cursor: none;
	}

	.lottie-el {
		margin: 0 4px 0 5px;
		transform: translateY(-2px);
		display: inline-block;
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.header {
		padding: var(--padding-desktop);
		.logo,
		.talk {
			width: 160px;
		}
		.talk {
			text-align: right;
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
