/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body.chrome::-webkit-scrollbar {
	background-color: rgba(0,0,0,0);
	width: 8px;
	height: 8px;
	z-index: 999999;
}

/* background of the scrollbar except button or resizer */
body.chrome::-webkit-scrollbar-track {
	background-color: rgba(0,0,0,0.1);
	transition: all 0.3s;
}

/* scrollbar itself */
body.chrome::-webkit-scrollbar-thumb {
	background-color: rgba(0,0,0,0.3);
	border-radius:16px;
	transition: all 0.3s;
}

/* set button(top and bottom of the scrollbar) */
body.chrome::-webkit-scrollbar-button {
	display:none;
}


/* scrollbar when element is hovered */
body:hover::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0,0,0,0.7);
	//border:4px solid #fff;
}

/* scrollbar when scrollbar is hovered */
body.chrome::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0,0,0,0.2);
	// border:4px solid #f4f4f4
}
