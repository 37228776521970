//Mobile------------------------------------------------------------------------------------------------------
.hero {
	padding-top: var(--padding-big);
	padding-inline: 1.75rem;
	text-align: center;

	p {
		font-weight: 400;
		font-size: 0.8rem;
	}
	h1 {
		margin-bottom: var(--padding-big);
	}
	h2 {
		font-size: 0.8rem;
		font-weight: normal;
		position: relative;
		display: inline-block;
		margin-bottom: 2.5rem;
		&:after {
			content: '';
			position: absolute;
			height: 1px;
			width: 100%;
			bottom: -0.5em;
			left: 0;
			background-color: var(--color-primary);
		}
	}
	.location {
		margin-bottom: 7.3rem;
	}
	.scroll-down {
		position: absolute;
		left: 0;
		bottom: -0.5em;
		top: auto;
		font-size: 13px;
		font-weight: 300;
		span {
			display: inline-block;
			transform: rotateZ(90deg) translateX(-55%) translateY(63px);
		}
	}
}
//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.hero {
		padding-block: 170px 100px;
		padding-inline: 1.75rem;
		text-align: left;
		h1 {
			text-align: center;
			margin-bottom: 120px;
		}
		.text-block {
			width: 40%;
			max-width: 22rem;
			&:first-child {
				margin-right: var(--padding-big);
			}
		}
	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {

}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
