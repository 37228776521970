@import '@splidejs/splide/css';

//Mobile------------------------------------------------------------------------------------------------------
.carousel {
	padding-top: 60px;
	padding-left: var(--padding-mobile);
	.slide-description {
		padding: var(--padding-mobile);
		position: relative;
		width: 100%;
		p {
			font-size: 0.75rem;
		}
	}

	.count {
		position: absolute !important;
		bottom: -10px;
		z-index: 1;
		right: calc(7% + 4px);
		font-size: 6.1875rem;
		span {
			transition: all 0.3s;
			display: inline-block;
			&.animating {
				opacity: 0;
				transform: translateX(-0.25em);
			}
		}
	}

	.splide__slide {
		width: 93%;
		margin-right: 12px;
		background-color: #222;
		&.showing {
			.carousel-ratio {
				img,
				video {
					//
				}
			}
		}
	}

	.carousel-ratio {
		height: 0;
		//padding-top: 100%;
		padding-top: 61.97916666666667%;
		overflow: hidden;
		position: relative;
		img,
		video {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: scale(1.025);
		}
	}

	.client,
	.collabs {
		transition: opacity 0.2s, transform 0.2s, margin-left 0s;
		&.animating {
			opacity: 0;
			transform: translateY(0.5em);
		}
	}

	.collabs {
		transition-delay: 0.1s;
	}

	.client,
	.collabs {
		&.shifted {
			//margin-left: 7%;
		}
	}

	.floater-trigger {
		display: none;
	}

}

.splide__arrow--prev,
.splide__arrow--next {
	display: none;
}

.splide__arrow--prev {
	pointer-events: none;
}

//Small tablet ------------------------------------------------------------------------------------------
@include media-breakpoint-up(sm) {

}


//Tablet portrait ---------------------------------------------------------------------------------------
@include media-breakpoint-up(md) {
	.carousel {
		padding-top: 95px;
		.slide-description {
			padding: calc(3 * (var(--padding-mobile))) calc(2 * (var(--padding-mobile)));

			p {
				font-size: 0.9375rem;
				width: 50%;
				&:first-child {
					margin-right: 120px;
				}
			}
		}



		.client{
			transition-delay: 0
		}

		.collabs {
			//transition-delay: 0.2s;
		}


	}
}


//Tablet landscape --------------------------------------------------------------------------------------
@include media-breakpoint-up(lg) {
	.carousel {

		.slide-description {
			p {
				width: 33.3333333%;
				max-width: 300px;
			}
		}
		.carousel-ratio {
			padding-top: 59.0625%;
		}

		.count {
			right: calc(15% + 4px);
		}

		.splide__arrow {
			display: block;
			position: absolute;
			top: 0;
			width: 93%;
			height: calc(100% - 80px);
			opacity: 0;
			transform: none;
			border-radius: 0;
			&:hover {
				opacity: 0;
			}
			svg {
				display: none;
			}
			&[disabled] {
				cursor: default;
			}
		}

		.splide__slide {
			width: 85%;
			margin-right: 100px;
		}

		.splide__arrow--prev {
			left: 0;
			right: 7%;
			display: none;
		}
		.splide__arrow--next {
			left: auto;
			right: 0%;
			width: 100%;
		}
	}
}


//Laptop ------------------------------------------------------------------------------------------------
@include media-breakpoint-up(xl) {

}


//Desktop -----------------------------------------------------------------------------------------------
@include media-breakpoint-up(xxl) {

}


//Hover states ------------------------------------------------------------------------------------------
@media (hover: hover) {

}


//Transitions (A11y reduced motion disabled) ------------------------------------------------------------
@media screen and (prefers-reduced-motion: no-preference) {

}
